@import "variables";
@import "fonts";
* {
    -webkit-overflow-scrolling: touch;
}

html {
  height: 100%;
  overscroll-behavior: none;
}
body {
  font-family: "Helvetica Neue",Helvetica,'Arial', sans-serif; 
  font-size: 1.8rem;
  line-height: 1.8;
  @media (max-width: $breakpointMobile) {
    font-size: 1.5rem;
    line-height: 1.5;
  }
  height: 100%;
  @media (min-width: $breakpointMobile) {
    font-size: 1.5rem;
  }
}
div, section, header {
  box-sizing: border-box;
}
h1 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 3rem;
  @media (min-width: $breakpointMobile) {
    margin-bottom: 4rem;
    font-size: 4rem;
  }
}
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
p.big-margin-bottom {
  margin-bottom: 4rem;
}
::selection {
  background-color: $colorTeal;
}
.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

.section {
  color: #fff;
}
.pp-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: $breakpointMobile) {
    padding-bottom: 90px;
  }
}
blockquote {
  position: relative;

  &:before,&:after {
    position: absolute;
    font-size: 16rem;
    color: $colorMedGray;
    opacity: .8;
    z-index:-5;
    line-height: 1;
  }
  &:before {
    content: '\201C';
    top: 0;
    left: 0;
    transform: translate(-50%, -30%);
  }
  &:after {
    content: '\201D';
    bottom: 0;
    right: 0;
    transform: translate(-30%,55%);
  }
  @media (min-width: $breakpointMobile) {
    &:before, &:after {
      font-size: 25rem;
    }
    &:before {
      top: 0;
      left: 0;
    }
    &:after {
      bottom: 0;
      right: 0;
    }
  }
}
figcaption.quote-attribution {
  // text-align: center;
  // &:before {
  //   content: '\2014';
  //   margin-right: .25rem;
  // }

}
button {
  background:none;
  border: none;
  box-shadow:none;
  cursor: pointer;
}

nav>ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

svg.video-player {
  fill: $colorOrange;
  rect {
    transition: opacity .25s ease-in-out;
    opacity: .65;    
  }
  // &:hover {
  //   rect {
  //     // opacity: 1;
  //   }
  // }
  transition: opacity .25s ease-in-out;
}

a {
  text-decoration: none;
  color: $colorTeal;
  &:not(.btn):hover {
    color: $colorTealDark;
  }
}
.btn {
  display: inline-block;
  background-color:$colorTeal;
  color: #fff;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight:700;
  padding: 10px 20px;
  text-decoration: none;
  &:hover {
    background-color: $colorTealDark;
  }
  svg {
    margin-left:10px;
    vertical-align: baseline;
  }
  @media(min-width: $breakpointMobile) {
    font-size: 1.6rem;
  }
}

.hide--mobile {
  @media(max-width: $breakpointMobile - 1) {
    display: none;
  }
}
.hide--desktop {
  @media(min-width: $breakpointMobile ) {
    display: none;
  }
}

.bg-teal {
  background-color: $colorTeal !important;
  color: #000;
}
.color-teal {
  fill: $colorTeal !important;
}
.bg-orange {
  background-color: $colorOrange !important;
  color: #000;
}
.bg-green {
  background-color: $colorGreen !important;
  color: #000;
}
.bg-white {
  background-color: #fff !important;
  color: #000;
}
.bg-yellow {
  background-color: $colorYellow !important;
  color: #000;
}
.bg-purple {
  background-color: $colorPurple !important; 
  color: #fff;
}
.bg-medGray {
  background-color: $colorMedGray !important;
  color: #fff;
}
.bg-gradient {
  background: #000;
  background: -webkit-linear-gradient(top, #3A3A3A, #010101);
  background: -moz-linear-gradient(top, #3A3A3A, #010101);
  background: linear-gradient(to bottom, #3A3A3A, #010101);   
}

.dialog-link {
  cursor: pointer;
}
/*** 
 * swiper pagination
 */
.swiper-pagination.swiper-pagination-lines {
  display: flex;
  justify-content: center;
  align-items: center;
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: $colorDarkGray;
    width: 20px;
    height: 5px;
    border-radius: 5px;
    margin: 0 10px;
    &.swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }
}  

/***
 * fancybox modals
 */
 .fancybox__backdrop {
  background: #000;
  background: -webkit-linear-gradient(top, #3A3A3A, #010101);
  background: -moz-linear-gradient(top, #3A3A3A, #010101);
  background: linear-gradient(to bottom, #3A3A3A, #010101);  
 }
 // .fancybox__content .carousel__button.is-close {
 //  position: fixed;
 //  top: 10px;
 //  right: 10px;
 //  top: calc(env(safe-area-inset-top, 0px) + 8px)
 // }
 .fancybox__toolbar {
  padding-top: calc(env(safe-area-inset-top, 0px) + 8px);
  padding-right: calc(env(safe-area-inset-right, 0px) + 8px);
 }
 .carousel__button.fancybox__button--zoom {
  display:none;
 }
 .carousel__button.fancybox__button--fullscreen {
  display: none;
 }
 .carousel__button.fancybox__button--thumbs {
  display: none;
 }
 .carousel__button.fancybox__button--slideshow {
  display: none;
 }
 .fancybox__counter {
  display: none;
 }

  .fancybox__image {
    // max-height: 90% !important;
  }
 .fancybox__caption {
  padding: 3rem 1rem 2rem 1rem;
  text-align: center;
  // position: absolute;
  // bottom: 5vh;
  // padding: 1rem 8px 0 8px;
  // text-align: center;
  &:not(.fancybox__caption2) {
    font-size: 1.6rem;
    @media (min-width:$breakpointMobile) {
      font-size: 1.8rem;
    }
  }
 }
 .fancybox__caption2 {
  padding-top: 0;
  font-size: 2.5rem;
  font-weight: bold;
 }
 .has-inline .fancybox__content {
  background-color: transparent;
  width: 100%;
  color: #fff;
 }
/*********
 * MENU
 */

.menu-toggle {
  padding: 0;
  position: relative;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 200;
  
  svg {
    width: 100%;
    stroke: #fff;
    transition: .2s;
    transition-delay: 2s;
    g {
      &:first-child {
        opacity: 1;
        transition: opacity 0s .1s;
        line {
          transition: transform .1s .1s;
          transform: translateY(0px);
        }
      }
      &:last-child {
        opacity: 0;
        transition: opacity 0s .1s;
        line {
          transition: transform .1s;
          transform: rotate(0deg);
          transform-origin: center;
        }
      }
    }
  }
  &.-menu-open svg {
    stroke: #fff; 
    g {
      &:first-child {
        opacity: 0;
        line {
          transition: transform .1s;
          &:first-child {
            transform: translateY(7px);
          }
          &:last-child {
            transform: translateY(-7px);
          }
        }
      }
      &:last-child {
        opacity: 1; 
        line {
          transition: transform .1s .1s;
          &:first-child {
            transform: rotate(45deg);
          }
          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }  
  }
}


#mainMenu {
  background: #000;
  background: -webkit-linear-gradient(top, #3A3A3A, #010101);
  background: -moz-linear-gradient(top, #3A3A3A, #010101);
  background: linear-gradient(to bottom, #3A3A3A, #010101);
  position: absolute;
  z-index:100;
  width: 100%;
  height: 100vh;
  padding: 20px 30px;
  box-sizing: border-box;
  top: 0;
  left:100%;

  transition: transform .7s ease;

  @media (min-width: $breakpointMobile) {
    padding: 40px 30px;
    width: 375px;
  }

  font-size: 1.3rem;
  ul {
    &>li {
      padding: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);

      &.top {
        padding-top: 0;
      }
      &>a.homeLink {
        font-weight: 700;
      }

      &>a, &>a:visited, &>a:active {
        color: $colorTeal;
        text-decoration:none;
        line-height:2.5rem;
      }
      &>a:hover {
        color: $colorTealDark;
      }
      &>a.menuLink {
        font-size: 1.8rem;
        font-weight: 700;
      }
      &>span {
        display: block;
        line-height:2rem;
        color: #fff;
      }
      @media(max-height: 600px) {
        a.menuLink {
          line-height: 2rem;
        }
      }      
    }
  }

  &.-menu-open {
    transform: translateX(-100%);
  }
}

.pp-nav-vertical-container {
  position: fixed;
  z-index:1000;

  left: 0;
  transform: translateX(0);
  width: 50%;
  display: flex;
  justify-content: space-between;

  bottom: calc(8vh + 30px);

  height: 0;
  .pp-nav-vertical {
    width: 60px;
    height: 60px;
    // transform: scale(3);
    svg {
      transform: scale(2.5);
    }
  }

  // just hide this in mobile
  display: none;

  @media(min-width:$breakpointMobile){
    text-align:center;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    &.pp-nav-vertical-down {
      transform:translateX(0);
    }
  }
}

.toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transform: translateY(1px);
  input {
    top:0;
    left:0;
    opacity: 0;
    width: 100%;
    // display: none;
    position: absolute;
    cursor: pointer;
  }
  label {
    display: block;
    width: 26px;
    height: 8px;
    border: 3px solid #fff;
    border-radius: 15px;
    // background-color: #000;
    span {
      color: #fff;
      text-transform: uppercase;
      font-size: .8rem;
      font-weight: 700;
      display: block;
      text-align:center;

      position: relative;
      transform: translateY(-15px);
    }
    &:before {
      display:block;
      left: 0px;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 7px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform: translate(18px, -50%);
      transition: transform .25s, background-color .25s;
      cursor: pointer;
      pointer-events: none;


    }
  }
  input:checked + label {
    // background-color: #000;
    
    &:before {
      // background-color: $colorTeal;
      // left: 60%;
      transform: translate(0, -50%);
    }
  }
}
/*********
 * HEADER
*/
#header {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  z-index: 200;
  padding: 20px;
  margin: 0;

  display: flex;
  justify-content: space-between;
  align-items: start;
  @media (min-width: $breakpointMobile) {
    padding: 40px;
  }
  #Logo {
    width: 63px;
    @media (min-width: $breakpointMobile) {
      width: 126px;
    }
  }
  .annual-report {
    display: block;
    font-weight: 600;
    color: white;
    // line-height: 3rem;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-right {
    display: flex;
    align-items: center;
    &>a,img,button,div {
      &:not(:last-child) {
        margin-right:30px;
        @media(min-width: $breakpointMobile) {
          margin-right:40px;
        }
      }
    }
  }
}

/********
 * MAIN
 */
#main {
  height: 100%;
  &>.section:first-of-type {
    
    &:not(.pp-section) {
      display:flex;
    }
  }
  &>.section {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  .video-bg {
      top: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: -100;

      filter: brightness(0.65);
      transition: filter .25s;
      &.paused {
        filter: brightness(.3);
      }
    }
  }
  .page-number {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 3vh;
    right: 0;
    display: flex;
    align-items:center;
    justify-content: center;

    font-size:2rem;
    line-height:2rem;
    font-weight:700;
    color: #fff;
    z-index: 1000;
    .outOf {
      font-size:1.8rem;
      opacity:.75;
    }
    @media(min-width: $breakpointMobile) {
      display: none;
    }
  }
}
.content {
  text-align: center;
  margin: auto;
  width: 100vw;
  padding: 0 30px;
  .center {
    margin: auto;
    max-width: $contentMaxWidth;
  }
}

@media(max-width: $breakpointMobile - 1) {
  .align-top-mobile {
    .pp-tableCell {
      vertical-align: top;
      .content {
        margin-top:90px;
      }
    }
    &>.content {
      margin-top: 90px;
      height: calc(100vh - 100px);
    }
  }
}
@media(min-width: $breakpointMobile) {
  .align-top-mobile {
    display:flex;
  }
  .align-top-desktop {
    .pp-tableCell {
      vertical-align: top;
      .content {
        margin-top:110px;
      }
    }    
  }
}

/***
 * Pagepiling navigation
 */
#pp-nav {
  display: none;
  @media(min-width: $breakpointMobile) {
    display: block;
  }
  li {
    width: auto;
    height: auto;
    margin: 0 0 20px 0;
    a {
      text-align: right;
      span {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 5px;
        border-radius: 5px;
        border-color: #999 !important;
        background-color: #999;
      }
    &.active span {
        width: 20px;
        border-color: #fff;
        background-color: #fff;
      }
    }

  }
}
/***
 * swiper navigation buttons
 */
.swiper-button-prev {
  margin-left:100px;
}
.swiper-button-next {
  margin-right: 100px;
}
.swiper-button-prev, .swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: $colorTeal;
  &.swiper-button-disabled {
    opacity: .4;
  }
  &:after{
    content:'';
  }
} 


/***
 * Deep partnerships
 */

#Deep-Partnerships {
  h1 {
    margin-top:2rem;
    margin-bottom: 2rem;
  }
  .content {
    max-width: 1000px;
  }
  .inner-wrapper {
    width: 100vw;
    margin-left: -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    order: 1;
    max-width: 600px;
    margin: 0;
    margin-bottom: 2rem;
  }
  .buttons {
    order: 1;
  }
  .swiper {
    width: 100%;
    margin-bottom: 4rem;

    .swiper-slide {
      width: 200px;
      height: 200px;

      img {
        // cursor: grab;
        width: 100%;
      }
    }    
  }
  @media(min-width: $breakpointMobile) {

    .inner-wrapper {
      width: auto;
      margin-left: 0;
      // width: 50%;
      // padding-right: 45%;
      position: relative;
      text-align: center;
    }

    .subtitle {
      order: 0;
      margin-bottom: 4rem;
    }
  }

  // Swiper paginaion
  .swiper-pagination {
    margin-bottom: 4rem;
    position: relative;
    bottom: auto;
  }

}
/***
 * Static sections
 */

.section.section-blog {
  background-image:url(../assets/quote-mobile.jpg);

  .pp-tableCell {
    vertical-align: top;
    backdrop-filter: brightness(0.7) contrast(0.9);
  }
  .content {
    margin-top:90px;
    text-align: center;
    height: calc(100% - 90px);
    padding-bottom: 9rem;
    .text-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
    }
    h1 {
      margin-bottom: auto;
    }
    .quote {
      max-width: 1000px;
      margin: 0;
      margin-bottom: 2rem;
      text-align: left;
      blockquote {
        font-size: 2rem;
        font-weight: bold;
        margin:0 0 2rem 2rem;
      }
      figcaption {
        margin-left: 2rem;
        font-style: italic;
        font-size:1.6rem;
        font-weight: bold;
      }
    }
  }
  @media(min-width: $breakpointMobile) {
    background-position: bottom center;
    background-image:url(../assets/quote-desktop.jpg);
    .content {
      padding-bottom: 10rem;
      h1 {
        margin-bottom: 18vh;
        margin-top: auto;
      }
      .quote {
        blockquote {
          font-size: 3rem;
        }
        figcaption {
          font-size: 2.5rem;
        }
      }
    }
  }
}
#Culture-and-people {
  .pp-tableCell {
    vertical-align: top;
    .content {
      margin-top:100px;
      &>.heading-wrapper {
        display: flex;
        flex-direction: column-reverse;
        @media(min-width: $breakpointMobile) {
          flex-direction: column;
        }
      }
    }
  }
  .people {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:20px;
    .item {
      .portrait {
        width: 100%;
      }
    }

    @media(min-width: $breakpointMobile) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 15px;
    }
  }
  .buttons {
    @media(min-width: $breakpointMobile) {
      margin-top:4rem;
    }
  }
}
@import "panels-3d";
#Partner-Spotlight {
  .content {
    max-width: 1000px;
    &>.subtitle {
      max-width: 305px;
      margin: 0 auto 60px;
    }
  }
  .orgs {
    .item {
      height: 80px;
      display: flex;

      p {
        font-size: 1.3rem;
      }
      .title {
        font-size: 1.5rem;
        line-height: 2.5rem;
        a {
          color: #fff;
          &:hover {
            color: $colorTeal;
          }          
        }
      }
      a {
        font-weight: 700;
        font-size: 1.5rem;
      }
      .img-wrapper {
        width: 135px;
        background-color: #fff;
        img {
          max-height:100%;
          max-width: 100%;
          background-color: #fff;
          padding: 15px;
          box-sizing: border-box;
        }
      }
      .text-wrapper {
        width: calc(100% - 135px);
        text-align: left;
        flex-grow: 1;
        padding: 0 20px;
        p:first-child {
          margin-top: 0;
        }
      }

      margin-bottom: 30px;
    }

    @media(min-width: $breakpointMobile) {
      .item {
        display: flex;
        height: auto;
        flex-direction: column;
        .img-wrapper {
          width: 100%;
          height: 90px;
        }
        .text-wrapper {
          text-align: center;
          flex-grow:1;
          padding: 0;
          display: flex;
          flex-direction: column;
          p:first-child {
            margin-top:1rem;
          }
          a:last-child {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
        margin-bottom: 20px;
      }
    }
  }
  .orgs.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(min-width: $breakpointMobile) {
      .item {
        width: 30%;
      }
    }
  }
  .orgs.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:20px;

    @media(min-width: $breakpointMobile) {
      grid-template-columns: repeat(8, 1fr);
      grid-gap: 15px;
    }
  }
}

#Contact-Us {
  // .pp-tableCell {
  //   vertical-align: top;
  // }
  .content {
    max-width: 1000px;
  }
  .contact-us-inner {
    justify-content: space-between;
  }
  .item {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
  }
  @media(min-width: $breakpointMobile) {
    .item {
      margin-bottom: .5rem;
    }
    .item-3 {
      width: 30%;
    }
    .item-5 {
      width:50%;
    }
    .item-6 {
      width: 60%;
    }
    .flex-wrapper {
      display: flex;
      flex-wrap:wrap;
    }
    // .apes-and-gibbons {
    //   text-align: right;
    // }
  }
  
  .contact-us-links {
    h1 {
      color: $colorTeal;
    }
    .arcus-slogan {
      font-size: 2.4rem;
      line-height: 3.5rem;
      font-weight: 700;
      margin: 0;      
    }
    &>p:last-of-type {
      margin-bottom: 1rem;
    }
    a:not(.btn) {
      color: #fff;
      text-decoration: underline;
    }
  }
  .social-media-links {
    font-size: 1.5rem;
    line-height: 3.5rem;
    font-weight: 700;  
    p {
      margin: 10px 0;
    }
    .icon-wrapper {
      display: inline-block;
      box-sizing: border-box;
      background-color: $colorOrange;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: 8px;
      margin-right: 10px;
      &>svg {
        width: 100%;
        height: 100%;
        vertical-align: baseline;
      }
    }
    &.apes-and-gibbons {
      .icon-wrapper {
        background-color: $colorGreen;
      }
    }
    @media (max-height: $breakpointMobile - 1) {
      p {
        margin: 0;
      }
      .icon-wrapper {
        width: 30px;
        height: 30px;
        &>svg {
          vertical-align: top;
        }
      }
    }
    margin-bottom: 0;
  }
  .copyright-info {
    text-align: left;
    font-size: 12px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

}
.bar-graph-with-images {
  .fancybox__slide {
    &:before {
      content: none;
    }

    .fancybox__caption2 {
      margin-bottom: 4vh;
    }
  }
}
.dialog-credits {
  .fancybox__slide {
    overflow: hidden;
    // background-color:black;
    // .fancybox__content {
    //   max-width: $breakpointMobile;
    //   position: absolute;
    //   // animation: moveUp 30s linear infinite 3s;

    //   padding-top:0;

    // }
  }
}
#Dialog-Credits {
  height: 100%;
  overflow:hidden;
  background-color: black;
  position: absolute;
  top: 0;
  .inner {
    margin: auto;
    max-width: $breakpointMobile;
    animation:  40s moveUp linear 1 2s, 43.4s moveUpFromBottom linear infinite 40s;
  }
  h1 {
    // height: 100vh;
    // line-height: 100vh;
    text-align: center;
    // margin-top: 0;
    // margin-bottom:0;
  }  
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-110%);
  }
}
@keyframes moveUpFromBottom {
  0% {
    transform: translateY(0);
    opacity: 0
  }
  4% {
    transform: translateY(0);
    opacity:1;
  }
  8% {
    tranform: translateY(0);
  } 
  100% {
    transform: translateY(-110%);
    opacity:1;
  }
}
/***
 * 2020 financials section
 */


.dialog-financials2020 .fancybox__content, #financials2020 {
  @media(max-width: $breakpointMobile) {
    padding: 0 50px;
    h1 {
      margin: 0;
      margin-bottom: 1rem;
    }

    p.button-wrapper {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }

  padding: 0 90px;
  text-align:center;
  .chart-wrapper {
    width: 100%;
    position:relative;
    display:block;
    max-width:380px;
    margin: auto;
    &>div {
      width: 100%;
    }
    .box-wrapper {
      .totals {
        display: flex;
        justify-content: space-around;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 20px;
        p {
          margin:0;
        }
        p:first-of-type{
          font-weight:700;
          // font-weight:1.4rem;
        }
        @media(min-width: $breakpointMobile) {
          font-size: 1.6rem;
        }
      }
      .box {
        position:relative;
        height:0;
        padding-bottom:100%;
        background-color: transparent;
        .wrapper {
          display:flex;
          position:absolute;
          width:100%;
          height: 100%;
          &>div{position:relative;}
        }
        .segment {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text {
            // margin:0;
            font-weight: bold;
            font-size: 1rem;
            @media(min-width: $breakpointMobile) {
              // font-weight: 700;
              // font-size: 1.6rem;
            }
            &>span {
              background-color:rgba(0,0,0,0.5);
              color: #fff;
              border-radius: 5px;
              padding: 3px 5px;
            }
          }
        }
      }

    }

    .legend-wrapper {
      padding: 0;
      padding-top: 1rem;
      display:flex;
      flex-direction: column;
      align-items: flex-start;

      .legend {
        margin:0 0 1rem 0;
        display: inline-block;
        list-style: none;
        padding: 0;
        &>li {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          line-height: 2rem;
          margin-bottom: 1rem;
          &:last-child {
            margin-bottom: 0;
          }
          .circle {
            min-width: 20px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-right: 10px;
          }
          @media (min-width: $breakpointMobile) {
            font-size: 1.6rem;
          }
          .legend-item-text {
            color: inherit;
            text-decoration: none;
            text-align: left;
            span {
              display: block;
            }
            .title {
              font-weight: 700;
            }
          }
          a.legend-item-text {
            &:hover {
              .title {
                color: #bbb;
              }
            }            
          }
        }
      }
    }
  }

  @media(min-width:$breakpointMobile) {

    .chart-wrapper {
      display: flex;
      max-width:760px;
      &>div {
        width: 50%;
      }

      .legend-wrapper {
        // align-items:center;
        padding-top: 6rem;
        padding-left: 6rem;
        padding-bottom: 0;
        justify-content: center;
      }
    }

  }

  [data-title="Special Grantmaking"] {
    .text {
      // display: none;
      // color: #fff;
      margin-bottom:.2rem;
    }
  }
}

.dialog-financials2020 .fancybox__content {
  .expenses, .grantmaking {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    &>p {
      margin:0;
      line-height:2rem;
    }
    &>p:first-child {
      font-weight:700;
    }
    @media(min-width: $breakpointMobile) {
      font-size: 1.6rem;
    }
  }
  .grantmaking {
    margin-bottom: 2rem;
  }
  .chart-wrapper {
    .box-wrapper {
      .box {
        &>div{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        p {
          margin:.5rem;
        }
        .amountDollars {
          font-size: 2.3rem;
          font-weight: 700;
          line-height: 2rem;
        }
        .amountGrants {
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.5rem;
        }
      }
    }
    .legend-wrapper {
      justify-content: flex-start;
      align-items: flex-start;

      .legend {
        li:not(.active) {
          opacity: .5;
          &:hover {
            opacity: .7;
            .title {
              color: #fff !important;
            }
          }
        }
      }
      @media(min-width: $breakpointMobile) {
        padding: 0 30px;
      }
    }
  }
}
.dialog-financials2020 .fancybox__slide:before {
  margin: 0;
}
@media(max-width: $breakpointMobile) {
  .dialog-financials2020 .fancybox__content {
    margin-top: -15px;
  }
  .dialog-financials2020 .fancybox__content, #financials2020 {
    .chart-wrapper {
      .legend-wrapper {
        .legend {
          &>li {
            margin-bottom: .5rem;
            .circle {
              min-width: 14px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }      
    }

  }
  #financials2020 {  
    margin-top: 80px;
    .chart-wrapper {
      .box-wrapper {
        .box {
          width: 65%;
          padding-bottom: 65%;
          margin: auto;
        }
      }

    }
  }
}

/***
 * Section 03 (video carousel)
 */
.section.video-carousel-01 {
  .pp-tableCell {
    vertical-align: top;   
  }
}
#video-carousel-01 {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: $breakpointMobile) {
    height: calc(100% - 110px);
  }
  .map-wrapper {

    top: 4rem;
    margin-bottom: 6rem;
    position: relative;
    
    z-index:-10;
    width:100%;
    max-width:800px;

    display: flex;
    max-height: 45vh;
    img {
      object-fit: contain;
      &:first-of-type {
        position: relative;
        top:0;
        left:0;
        transform: translateX(0);
        width: 100%;
      }

      &.map-overlay {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform:translateX(-50%);
        transition: opacity .1s ease;
        opacity:0;
        &.active {
          opacity: 1;
        }
      }
    }

    @media(min-width: $breakpointMobile) {
      top:0;
      // margin-bottom: 4rem;
    }

  }
  .heading { 
    // height: 35%;
    position: absolute;
    // margin-top: 70px;
    margin-top: 0;
    
    margin-left: auto;
    margin-right: auto;

    // flex-grow: 1;
    .subtitle {
      line-height:3.5rem;
    }
    @media(min-width: $breakpointMobile) {
      // height: 50%;
      // margin-top:110px;
    }
  }

  .carousel-outer-2 {
    align-self: stretch;
    position: relative;
    margin-top: 0;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 4rem;
    .carousel-outer {
      // margin: 0 90px 0;
      padding: 0 90px 0;
      .carousel-inner {
        align-items: center;
        .item {
          .image-wrapper {
            position: relative;
            opacity: .25;
            margin: auto;
            svg {
              position: absolute;
              top: 50%;
              left:50%;
              transform:translate(-50%,-50%);
            }
            video, img {
              width:100%;
            }
            @media (min-width: $breakpointMobile) {
              // 16:9 ratio
              width: 160px;
              height: 89px; 
            }
          }
          &[data-type="apes"] {
            .image-wrapper {
              .video-player {
                fill: $colorGreen;
              }
            }
          }
          &.swiper-slide-active, &.swiper-slide-prev, &.swiper-slide-next {
            .image-wrapper {
              opacity: 1;
            }
          }
        }
      }

    }
    .swiper-button-prev, .swiper-button-next {
      top: calc(50% - 20px);
      margin-top: 0 !important;
    }
    @media(max-width: $breakpointMobile - 1) {
      .swiper-slide:not(.swiper-slide-active) {
        // opacity: 0;
        filter: brightness(.3) saturate(.4);
        cursor: grab !important;
        a {
          cursor: grab;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        display: none;
        margin: 0;
      }
      .swiper-button-prev {
        left:0;
      }
      .swiper-button-next {
        right: 0;
      }
    }

  }
  .bottom-text {
    margin-bottom: 4rem;
    line-height: 2.5rem;
    font-size: 2rem;
    @media(min-width: $breakpointMobile) {
      // margin-bottom: 1rem;
    }

  }
  .swiper-pagination {
    position: relative;
    bottom: auto;
    margin-bottom: 11rem;
  }   
  @media (max-height: 900px) and (min-width: $breakpointXsmall) {
    .map-wrapper {
      position: absolute;
      top: 110px;
    }
    .heading {
      position: relative;
      // margin-bottom: 14rem;
      flex-grow: 1;
    }
    // .swiper-pagination {
    //   bottom: calc(8vh + 30px);
    // }
  }  
}

/***
 * grant-amount-by-year graph
 */

 #grant-amount-by-year {
    width: 100%;
    .show-amount {
      font-size: 1.6rem;
      line-height: 3.5rem !important;
      margin-bottom: 2.4rem;
      @media(min-width: $breakpointMobile) {
        margin-bottom: 4rem;
      }
      .sign {
        display: inline-block;
        position: relative;
        margin-right: .4rem;
        top: 1px;
      }
    }
    .bar-chart {
      height: 260px;
      .item {
        display: flex;
        flex-direction: column;
        .bar-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;          
          .bar {
            cursor: pointer;
            background-color: $colorTeal;
            opacity: .3;
          }
        }
        &>div {
          margin: auto;
        }
        .year-wrapper {
          cursor: pointer;
          opacity: .6;
        }
        &:hover {
          .bar { opacity: .5; }
          .year-wrapper { opacity: .8; }
        }
        .year {
          line-height: 35px;
        }
        .year-image {
          position: absolute;
          left: 85%;
          right: auto;
          z-index:-100;
          width: 100px;
          opacity: 0;
          pointer-events: none;
          img {
            width: 100%;
          }
          &:nth-of-type(1) {
            top:calc(0% - 20px);
            transform:translate(120%,0);
          }
          &.image-4:nth-of-type(1) {
            top: calc(3% - 20px);
            transform:translate(-120%, 0);
            right:100%;
            left: auto;
          }

          &:nth-of-type(2) {
            top: calc(50% - 25px);
            transform:translate(-120%,-50%);
            left:auto;
            right: 85%;
          }
          &.image-4:nth-of-type(2) {
            top: calc(0% - 20px);
            transform:translate(120%, 0);
            left: 85%;
            right: auto;
          }
          &:nth-of-type(3) {
            bottom:55px;
            transform:translate(120%,0);
          }
          &.image-4:nth-of-type(3) {
            bottom: calc(15% + 25px);
            transform:translate(-120%,0);
            left:auto;
            right: 75%;            

          }          
          &:nth-of-type(4) {
            bottom:55px;
            transform:translate(120%,0);
          }
          transition: transform 1s ease, opacity 1s ease;
        }
        &.swiper-slide-active {
          .bar, .bar:hover, .year-wrapper, year-wrapper:hover {
            opacity: 1 !important;
          }
          .year-image {
            z-index:100;
            transform:translate(0,0);
            opacity: 1;
            pointer-events: auto;
            &.image-4:nth-of-type(1) {
              transform: translate(0, 0);
            }

            &:nth-of-type(2) {
              transform:translate(0,-50%);
            }
            &.image-4:nth-of-type(2) {
              transform: translate(0, 0);
            }
            &.image-4:nth-of-type(3) {
              transform:translate(0, 0);
            }
            &:nth-of-type(4) {
              transform: translate(0, 0);
            }
          }
        }
      }

    }
    .swiper-button-prev, .swiper-button-next {
      display: none;
      // @media (min-width:$breakpointMobile) {
      //   display: flex;
      // }
    }
    .swiper-scrollbar-wrapper {
      width: 195px;
      position:relative;
      margin: auto;
      height: 5px;
      .swiper-scrollbar {
        height: 5px;
        background-color: $colorDarkGray;
        cursor:pointer;
        .swiper-scrollbar-drag {
          background-color: #fff;
        }
      }

      margin-top: 50px;
      @media(min-width: $breakpointMobile) {
        margin-top: 70px;
      }
    }
 }


/***
 * Range slider
 */
.slider {
  -webkit-appearance: none;
  height: 5px;
  border-radius: 30px;
  background: $colorDarkGray;
  cursor: pointer;
  // outline: none;
  // -webkit-transition: .2s;
  // transition: opacity .2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 5px;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 5px;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
}

/***
 * Hide main and header 
 */
