@font-face {
  font-family: 'Helvetica Neue';
  src: url('../assets/fonts/HelveticaNeueLt.ttf') format('truetype');
  // src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //      url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
  //      url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
  //      url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
  //      url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: "Helvetica Neue";
    src: url('../assets/fonts/HelveticaNeueBd.ttf') format('truetype'); 
    font-weight: bold;
}
@font-face {
    font-family: Helvetica;
    src: url('../assets/fonts/Helvetica.ttf') format('truetype'); 
}
@font-face {
    font-family: Helvetica;
    src: url('../assets/fonts/Helvetica-Bold.ttf') format('truetype'); 
    font-weight: bold;
}
