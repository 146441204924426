$breakpointXsmall: 414px;
$breakpointMobile: 750px;
$breakpointMedium: 1024px;
$breakpointLarge: 1200px;
$breakpointXLarge: 1420px;

// $breakpointMinHeightSmall: 700px;

$contentMaxWidth: 767px;

$colorMedGray: #5C5C5C;
$colorDarkGray: #424242;
$colorTeal: #58C0CA;
$colorTealDark: #44A5AE;
$colorGreen: #82AE49;
$colorOrange: #EF8933;
$colorYellow: #FED52D;
$colorPurple: #541F69;