  #Partner-Spotlight {
    // background-image:url(../assets/bg-radial.png);

    h1 {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    .subtitle {
      margin-bottom: 2rem;
    }

    .swiper {
      width: 100%;
      margin-bottom: 4rem;
      -webkit-mask-image: linear-gradient(
        to left, 
        transparent 0, 
        // transparent 2%, 
        black 45%,
        black 55%,
        // transparent 98%,
        transparent 100%
      );
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 109%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: black;
        z-index: 100;
        opacity: 0.99;
        mix-blend-mode: multiply;
        -webkit-mask-image:url(../assets/shade2.png);
        mask-image: url(../assets/shade2.png);
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        pointer-events: none;
      }
      &:after {
        content:"";
        position: absolute;
        width: 100%;
        height: 109%;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 0);
        background-image:url(../assets/light-floor.png);
      }
      .swiper-slide {
        width: 200px;
        height:200px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
        // transition: filter .25s;
        // &:not(.swiper-slide-active) {
        //   filter: brightness(.3);
        // }
        // &.swiper-slide-active {
        //   &:before {
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     left: 0; 
        //     top: 0;
        //     background-color: #000;
        //     opacity: .5;
        //     -webkit-mask-image: url(../assets/shade2.png);
        //     mask-image: url(../assets/shade2.png);
        //     mask-size: cover;
        //     -webkit-mask-size: cover;
        //   }
        // }
      }
    }
  }
  #Partner-Spotlight .pp-tableCell {
    backdrop-filter:brightness(.6);
  }
  #Partner-Spotlight  .title {
    text-align: center;
    position:relative;
    z-index: 1;
    color: #fff;

  }
.panels-3d {
  // z-index: 1000;
  width: 100vw;
  // height: 100vh;
  top: 0;
  left: 0;
  height:100vh;
  position: absolute;
  transform-style: preserve-3d;

  -webkit-mask-image: linear-gradient(
    to bottom, 
    transparent 0, 
    transparent 2%, 
    black 35%,
    black 65%,
    transparent 98%,
    transparent 0
  );
  // @media (max-width: $breakpointMobile + 1) {
  //   -webkit-mask-image: linear-gradient(
  //     to bottom, 
  //     transparent 0, 
  //     transparent 90px, 
  //     black 35%,
  //     black 65%,
  //     transparent calc(100% - 90px),
  //     transparent 0
  //   );    
  // }
  // -webkit-mask-image: radial-gradient(
  //   black 0, 

  //   transparent 70%
  // );  
  .panel {
    box-sizing: border-box;
    background-color: transparent;
    // padding: 15px;


    position:fixed;
    // width: 12.5%;

    // top:100%;
    bottom: 50%;
    left: 50%;


    &>a {
      // padding:15px;
      display: block;
      width: 100%;
      height:100%;
    }
    img.logo {
      width: 100%;
    }

    transform-origin: center;
    // animation: moveUp 12s linear infinite;
    // transition: transform 2s ease, bottom 2s ease, left 2s ease;
    &:hover {
      animation-play-state: paused;
    }

    // transition: opacity .3s;
    // opacity: 1;
    // &.fading {
    //   opacity: 0;
    // }
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(400px);
  }
  100% {
    transform: translateY(-1400px);
  }
}